import type { Brand } from "./shared/types";

export const brand: Brand = {
  origins: [],
  id: "evangelist",
  name: "Evangelist",
  logoUrl: "/logos/logo-evangelist.svg",
  designSchema: {
    borderRadius: "24px",
    bgColor: "#f3f7fa",
    primaryColor: "#447a77",
    primaryColorContrast: "#ffffff",
    tweetComposer: {
      bgColor: "#baddd3",
      bgColorWithBonus: "#CFCBFF",
      positiveBulletColor: "#000",
      positiveBulletBackgroundColor: "#fff",
    },
  },
  eligibilityDescriptions: {
    "not-eligible": [""],

    evaluating: [""],
  },
  campaigns: [],
};
